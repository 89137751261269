import { parseDate } from '../../../dateUtils'

const mapRegularDeliveries = ({ status, subscriptionDetails, message }) => ({
  status: status.toLowerCase(),
  message,
  subscriptions: subscriptionDetails?.map(mapDetails) || null,
})

const statusMap = {
  pause: 'paused',
  cancelled: 'canceled',
  active: 'active',
}

const mapDetails = ({
  id,
  status,
  name,
  interval,
  isEditable,
  price,
  deliveryAddress,
  paymentType,
  nextDeliveryDate,
  subscriptionItems,
}) => ({
  id,
  status: statusMap[status] || status,
  isEditable: !!parseInt(isEditable),
  label: name,
  deliveryInterval: parseInt(interval),
  nextDeliveryDate: parseDate(nextDeliveryDate, 'dd.MM.yyyy', '.'), // Fixed format from Magento
  price,
  paymentType,
  deliveryAddress: mapAddress(deliveryAddress),
  items: subscriptionItems.map(mapItems),
})

const mapAddress = ({
  firstname,
  lastname,
  company,
  street,
  postcode,
  city,
  country,
}) => ({
  firstName: firstname,
  lastName: lastname,
  company,
  street,
  postcode,
  city,
  country,
})

const mapItems = ({
  productId,
  sku,
  numOrdered,
  name,
  image,
  manufacturer,
  produkt_linie,
  config_variable_a,
  unitPrice,
  active,
  availability,
}) => ({
  productId,
  sku,
  name,
  manufacturer,
  productLine: produkt_linie || null,
  imageUrl: image,
  qty: parseInt(numOrdered),
  unitPrice,
  configVariableA: config_variable_a,
  active,
  availability,
})

export { mapRegularDeliveries }
